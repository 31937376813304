import { wwpassQRCodeAuth } from './qrcode/wwpass.qrcode';
import { wwpassPasskeyAuth } from './passkey/auth';

import openWithTicket from './open';
import { isClientKeyTicket } from './ticket';
import {
  pluginPresent,
  waitForRemoval,
  WWPASS_STATUS
} from './passkey/passkey';

import { WWPassCrypto, WWPassCryptoPromise } from './wwpass.crypto';

import { version } from '../package.json';

const init = (initialOptions) => {
  const defaultOptions = {
    ticketURL: '',
    callbackURL: '',
    hw: false,
    ppx: 'wwp_',
    version: 2,
    log: () => {}
  };

  const options = Object.assign({}, defaultOptions, initialOptions);
  options.passkeyButton = (typeof options.passkey === 'string') ? document.querySelector(options.passkey) : options.passkey;
  options.qrcode = (typeof options.qrcode === 'string') ? document.querySelector(options.qrcode) : options.qrcode;

  const promises = [];

  if (options.passkeyButton) {
    promises.push(wwpassPasskeyAuth(options));
  }
  promises.push(wwpassQRCodeAuth(options));
  return Promise.race(promises);
};

if ('console' in window && window.console.log) {
  window.console.log(`WWPass universal library version ${version}`);
}

const onKeyRemoved = (callback) => {
  waitForRemoval().then(callback);
};

window.WWPass = {
  init,
  openWithTicket,
  onKeyRemoved,
  pluginPresent,
  STATUS: WWPASS_STATUS,
  isClientKeyTicket
};

window.WWPassCrypto = {
  initWithTicket: ticket => new WWPassCrypto(ticket),
  promiseWithTicket: ticket => WWPassCryptoPromise.getWWPassCrypto(ticket)
};
