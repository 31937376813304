import QRCode from 'qrcode';
import { getWebSocketResult, closeConnectionPool } from './wwpass.websocket';
import { ticketAdapter } from './../ticket';
import {
  encodeClientKey
} from './../crypto';

import { getUniversalScheme } from './../scheme';
import navigateToCallback from './../navigation';

import { getClientNonceWrapper } from './../nonce';

import { WWPASS_STATUS } from '../passkey/constants';

// todo: return style when qrcode updating
// const DEFAULT_WAIT_CLASS = 'focused';
// style.transition = 'all .4s ease-out';
// style.opacity = '.3';

const PROTOCOL_VERSION = 2;

const getJSON = url => fetch(url).then((response) => {
  if (!response.ok) {
    throw Error(`Error fetching ticket from "${url}": ${response.statusText}`);
  }
  return response.json();
});

const removeChildren = (element) => {
  while (element.firstChild) {
    element.removeChild(element.firstChild);
  }
};

const debouncePageVisibilityFactory = (state = 'visible') => {
  let debounce = null;
  return (fn) => {
    debounce = fn;

    const onDebounce = () => {
      if (document.visibilityState === state) {
        debounce();
        document.removeEventListener('visibilitychange', onDebounce);
      }
    };

    if (document.visibilityState === state) {
      debounce();
    } else {
      document.addEventListener('visibilitychange', onDebounce);
    }
  };
};

const debouncePageVisible = debouncePageVisibilityFactory();

const isMobile = () => navigator &&
    'userAgent' in navigator &&
    navigator.userAgent.match(/iPhone|iPod|iPad|Android/i);

const renderQRcode = (initialOptions) => {
  if (!initialOptions.ticket) {
    throw Error('Ticket not found');
  }

  if (!initialOptions.callbackURL) {
    throw Error('Callback URL not found');
  }

  const defaultOptions = {
    version: PROTOCOL_VERSION,
    ppx: 'wwp_',
    spfewsAddress: 'wss://spfews.wwpass.com/',
    key: undefined
  };

  const options = Object.assign({}, defaultOptions, initialOptions);
  const scheme = getUniversalScheme({
    ticket: options.ticket,
    clientKey: options.key ? encodeClientKey(options.key) : undefined
  });

  const canvasElement = document.createElement('canvas');
  QRCode.toCanvas(canvasElement, scheme, (error) => {
    if (error) {
      throw error;
    }
  });

  if (isMobile()) {
    const universalLinkElement = document.createElement('a');
    const url = getUniversalScheme({
      universal: options.universal,
      ticket: options.ticket,
      callbackURL: options.callbackURL,
      ppx: options.ppx,
      version: PROTOCOL_VERSION,
      clientKey: options.key ? encodeClientKey(options.key) : undefined
    });
    universalLinkElement.href = url;

    universalLinkElement.appendChild(canvasElement);
    return universalLinkElement;
  }

  return canvasElement;
};

const QRCodePromise = (universalLinkElement, ttl, log) => new Promise((resolve) => {
  if (isMobile()) {
    universalLinkElement.addEventListener('click', () => {
      resolve({ away: true });
    });
  }
  setTimeout(() => {
    log('timer triggered');
    log((new Date()).toUTCString());

    debouncePageVisible(() => {
      log('debouncePageVisible triggered');
      log((new Date()).toUTCString());
      resolve({ refresh: true });
    });
  }, ttl * 900);
});

const addQRCodeDefaultStyles = (root, parent, qrcodeStyleOptions) => {
  const qrcodeClass = `${qrcodeStyleOptions.prefix}qrcode--inner-style`;
  if (!parent.classList.contains(qrcodeClass)) {
    // add style in page
    // TODO: refactore
    const style = document.createElement('style');
    const rules = [
      `.${qrcodeClass} canvas`,
      '{',
      'width: 256px !important;',
      'height: 256px !important;',
      '}'
    ];

    style.innerHTML = rules.join(' ');
    root.appendChild(style);
    parent.classList.add(qrcodeClass);
  }
};

/*
 * WWPass QR code auth function
 *
options = {
    'ticketURL': undefined, // string
    'callbackURL': undefined, // string
    'development': false, // work with dev server
    'log': function (message) || console.log, // another log handler
}
 */
const wwpassQRCodeAuth = (initialOptions) => {
  const defaultOptions = {
    universal: false,
    ticketURL: undefined,
    callbackURL: undefined,
    development: false,
    version: 2,
    ppx: 'wwp_',
    spfewsAddress: 'wss://spfews.wwpass.com',
    qrcodeStyle: {
      prefix: 'wwp_',
      color: 'black',
      background: 'white'
    },
    log: () => {}
  };

  const options = Object.assign({}, defaultOptions, initialOptions);
  const log = options.log;

  if (!options.ticketURL) {
    throw Error('ticketURL not found');
  }

  if (!options.callbackURL) {
    throw Error('callbackURL not found');
  }

  if (!options.qrcode) {
    throw Error('Element not found');
  }

  const QRroot = options.qrcode;

  if (options.qrcodeStyle) {
    addQRCodeDefaultStyles(document.body, QRroot, options.qrcodeStyle);
  }

  let ticket = null;
  let ttl = null;

  return getJSON(options.ticketURL)
  .then((json) => {
    const response = ticketAdapter(json);
    ticket = response.ticket;
    ttl = response.ttl;
    return getClientNonceWrapper(ticket, ttl);
  }).then((key) => {
    const qrcodeElement = renderQRcode({
      ticket,
      callbackURL: options.callbackURL,
      log,
      key
    });
    removeChildren(QRroot);
    const qrcodeRefreshPromise = QRCodePromise(qrcodeElement, ttl, options.log);
    const websocketResultPromise = getWebSocketResult({
      callbackURL: options.callbackURL,
      ticket,
      log,
      development: options.development,
      version: options.version,
      ppx: options.ppx,
      spfewsAddress: options.spfewsAddress
    });
    QRroot.appendChild(qrcodeElement);
    return Promise.race([
      qrcodeRefreshPromise,
      websocketResultPromise
    ]);
  }).then((result) => {
    if (result.refresh) {
      return wwpassQRCodeAuth(initialOptions);
    }
    if (result.clientKey && options.catchClientKey) {
      options.catchClientKey(result.clientKey);
    }
    if (result.away) {
      log('closeConnectionPool');
      removeChildren(QRroot);
      closeConnectionPool();
      return {
        ppx: options.ppx,
        version: options.version,
        status: WWPASS_STATUS.CONTINUE,
        reason: 'User has clicked on QRCode',
        ticket: options.ticket,
        callbackURL: options.callbackURL
      };
    }
    navigateToCallback(result);
    return result;
  })
  .catch((err) => {
    if (!err.status) {
      log('QRCode auth error', err);
    } else if (err.status === WWPASS_STATUS.INTERNAL_ERROR || options.returnErrors) {
      navigateToCallback(err);
    }
    throw err;
  });
};

export {
  getJSON,
  wwpassQRCodeAuth,
  renderQRcode
};
