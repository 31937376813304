import { WWPASS_STATUS, WWPASS_UNSUPPORTED_PLATFORM_MSG_TMPL } from './constants';

const prefix = (window.location.protocol === 'https:') ? 'https:' : 'http:';
const CSS = `${prefix}//cdn.wwpass.com/packages/wwpass.js/2.4/wwpass.js.css`;

const isNativeMessaging = () => {
  const userAgent = navigator.userAgent;

  let re = /Firefox\/([0-9]+)\./;
  let match = userAgent.match(re);
  if (match && match.length > 1) {
    const version = match[1];
    if (Number(version) >= 51) {
      return 'Firefox';
    }
  }

  re = /Chrome\/([0-9]+)\./;
  match = userAgent.match(re);
  if (match && match.length > 1) {
    const version = match[1];
    if (Number(version) >= 45) {
      return 'Chrome';
    }
  }

  return false;
};

const wwpassPlatformName = () => {
  const userAgent = navigator.userAgent;
  const knownPlatforms = ['Android', 'iPhone', 'iPad'];
  for (let i = 0; i < knownPlatforms.length; i += 1) {
    if (userAgent.search(new RegExp(knownPlatforms[i], 'i')) !== -1) {
      return knownPlatforms[i];
    }
  }
  return null;
};

const wwpassMessageForPlatform = platformName => `${WWPASS_UNSUPPORTED_PLATFORM_MSG_TMPL} ${platformName}`;


const wwpassShowError = (message, title, onCloseCallback) => {
  if (!document.getElementById('_wwpass_css')) {
    const l = document.createElement('link');
    l.id = '_wwpass_css';
    l.rel = 'stylesheet';
    l.href = CSS;
    document.head.appendChild(l);
  }

  const dlg = document.createElement('div');
  dlg.id = '_wwpass_err_dlg';

  const dlgClose = document.createElement('span');
  dlgClose.innerHTML = 'Close';
  dlgClose.id = '_wwpass_err_close';

  const header = document.createElement('h1');
  header.innerHTML = title;

  const text = document.createElement('div');
  text.innerHTML = message;


  dlg.appendChild(header);
  dlg.appendChild(text);
  dlg.appendChild(dlgClose);
  document.body.appendChild(dlg);

  document.getElementById('_wwpass_err_close').addEventListener('click', () => {
    const elem = document.getElementById('_wwpass_err_dlg');
    elem.parentNode.removeChild(elem);
    onCloseCallback();
    return false;
  });
  return true;
};

const wwpassNoSoftware = (code, onclose) => {
  if (code === WWPASS_STATUS.NO_AUTH_INTERFACES_FOUND) {
    const client = isNativeMessaging();
    let message = '';
    if (client) {
      if (client === 'Chrome') {
        const returnURL = encodeURIComponent(window.location.href);
        message = '<p>The WWPass Authentication extension for Chrome is not installed or is disabled in browser settings.';
        message += '<p>Click the link below to install and enable the WWPass Authentication extension.';
        message += `<p><a href="https://chrome.wwpass.com/?callbackURL=${returnURL}">Install WWPass Authentication Extension</a>`;
      } else
            if (client === 'Firefox') {
                // Firefox
              const returnURL = encodeURIComponent(window.location.href);
              message = '<p>The WWPass Authentication extension for Firefox is not installed or is disabled in browser settings.';
              message += '<p>Click the link below to install and enable the WWPass Authentication extension.';
              message += `<p><a href="https://firefox.wwpass.com/?callbackURL=${returnURL}">Install WWPass Authentication Extension</a>`;
            } else {
                // Wait Edge
            }
    } else {
      message = '<p>No Security Pack is found on your computer or WWPass&nbsp;Browser&nbsp;Plugin is disabled.</p><p>To install Security Pack visit <a href="https://ks.wwpass.com/download/">Key Services</a> or check plugin settings of your browser to activate WWPass&nbsp;Browser&nbsp;Plugin.</p><p><a href="https://support.wwpass.com/?topic=604">Learn more...</a></p>';
    }
    wwpassShowError(message, 'WWPass &mdash; No Software Found', onclose);
  } else if (code === WWPASS_STATUS.UNSUPPORTED_PLATFORM) {
    wwpassShowError(wwpassMessageForPlatform(wwpassPlatformName()), 'WWPass &mdash; Unsupported Platform', onclose);
  }
};

export {
  wwpassNoSoftware,
  wwpassShowError,
  wwpassMessageForPlatform
};
