import { getWebSocketResult } from './qrcode/wwpass.websocket';
import { ab2str, str2ab, abToB64, b64ToAb } from './ab';
import { encrypt, decrypt, importKey, getRandomData, concatBuffers } from './crypto';
import { getClientNonce } from './nonce';


const buffer = [176, 178, 97, 142, 156, 31, 45, 30, 81, 210, 85, 14, 202, 203, 86, 240];
const clientKeyIV = new Uint8Array(buffer);

class WWPassCryptoPromise {
  static getWWPassCrypto(ticket) {
    let encryptedClientKey = null;
    return getWebSocketResult({ ticket, clientKeyOnly: true })
    .then((result) => {
      if (!result.clientKey) {
        throw Error(`No client key associated with the ticket ${ticket}`);
      }
      encryptedClientKey = result.clientKey;
      return getClientNonce(ticket);
    })
    .then(key => importKey('raw', key, { name: 'AES-CBC' }, false, [
      'encrypt',
      'decrypt',
      'wrapKey',
      'unwrapKey'
    ]))
    .then(clientKeyNonce => decrypt({ name: 'AES-CBC', iv: clientKeyIV }, clientKeyNonce, b64ToAb(encryptedClientKey)))
    .then(arrayBuffer => importKey('raw', arrayBuffer, { name: 'AES-GCM' }, false, [
      'encrypt',
      'decrypt',
      'wrapKey',
      'unwrapKey']))
    .then(key => new WWPassCryptoPromise(key));
  }

  encryptArrayBuffer(arrayBuffer) {
    const iv = new Uint8Array(16);
    getRandomData(iv);
    return encrypt({ name: 'AES-GCM', iv }, this.clientKey, arrayBuffer).then(encryptedAB => concatBuffers(iv.buffer, encryptedAB));
  }
  encryptString(string) {
    return this.encryptArrayBuffer(str2ab(string)).then(abToB64);
  }
  decryptArrayBuffer(encryptedArrayBuffer) {
    return decrypt({ name: 'AES-GCM', iv: encryptedArrayBuffer.slice(0, 16) }, this.clientKey, encryptedArrayBuffer.slice(16));
  }
  decryptString(encryptedString) {
    return this.decryptArrayBuffer(b64ToAb(encryptedString)).then(ab2str);
  }
  // Private
  constructor(key) {
    this.clientKey = key;
    this.queue = [];
  }
}

class WWPassCrypto {

  constructor(ticket) {
    this.queue = [];
    if (window.console && window.console.error) {
      window.console.error('WWPassCrypto.initWithTicket is deprecated. Use WWPassCrypto.promiseWithTicket istead');
    }
    this.cryptoPromise = WWPassCryptoPromise.getWWPassCrypto(ticket);
  }

  encryptArrayBuffer(arrayBuffer) {
    return this.cryptoPromise.then(crypto => crypto.encryptArrayBuffer(arrayBuffer));
  }
  encryptString(string) {
    return this.cryptoPromise.then(crypto => crypto.encryptString(string));
  }
  decryptArrayBuffer(encryptedArrayBuffer) {
    return this.cryptoPromise.then(crypto => crypto.decryptArrayBuffer(encryptedArrayBuffer));
  }
  decryptString(encryptedString) {
    return this.cryptoPromise.then(crypto => crypto.decryptString(encryptedString));
  }
}

export { WWPassCrypto, WWPassCryptoPromise };
